import React, { useState } from 'react';
import './SectionCard.css';
import { FiEdit } from 'react-icons/fi';
import axios from 'axios';

const SectionCard = ({ icon, title, id, content, isAuthenticated, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(content);

  const handleEdit = () => {
    setIsEditing(true);
    setEditedText(content);
  };

  const handleSave = () => {
    onSave(editedText);
    setIsEditing(false);
  };

  const handleChange = (e) => {
    setEditedText(e.target.value);
  };

  return (
    <div className="section-card">
      <div className="icon">{icon}</div>
      <h2>{title}</h2>
      {isEditing ? (
        <textarea
          value={editedText}
          onChange={handleChange}
          className="edit-textarea"
        />
      ) : (
        <p>{content.split('\n').map((line, index) => (
          <span key={index}>{line}<br/></span>
        ))}</p>
      )}
      {isAuthenticated && (
        <div className="button-container">
          {isEditing ? (
            <button onClick={handleSave} className="save-button">Save</button>
          ) : (
            <button onClick={handleEdit} className="edit-button">
              <FiEdit /> Edit
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default SectionCard;
