import React from 'react';
import SuccessRate from '../../Components/SuccessRate/SuccessRateComponent';
import './ResultatBrevet.css';

const ResultatBrevet = () => {
  return (
    <div className="resultat-brevet">
      <h1>Résultats au Brevet</h1>
      <p>Consultez les résultats au brevet du collège.</p>
      <div className="success-rate-list">
        <SuccessRate />
      </div>
    </div>
  );
};

export default ResultatBrevet;
