import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import './Gallery.css';

Modal.setAppElement('#root'); // Ajouter ceci pour des raisons d'accessibilité

const Gallery = ({ isHomePage }) => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    }
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/images`);
      setImages(response.data);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const openModal = (image) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalIsOpen(false);
  };

  const handleDelete = async (imageId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/images/${imageId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      fetchImages(); // Refresh images after delete
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    if (!file) {
      console.error('No file selected');
      return;
    }

    const formData = new FormData();
    formData.append('image', file);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/images/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setFile(null); // Réinitialiser l'input de fichier après le téléchargement
      fetchImages(); // Fonction pour récupérer les images après le téléchargement (à définir)
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleNavigation = (direction) => {
    const currentIndex = images.findIndex(image => image._id === selectedImage._id);
    let newIndex = currentIndex;

    if (direction === 'prev') {
      newIndex = (currentIndex === 0) ? images.length - 1 : currentIndex - 1;
    } else if (direction === 'next') {
      newIndex = (currentIndex === images.length - 1) ? 0 : currentIndex + 1;
    }

    setSelectedImage(images[newIndex]);
  };

  // Filtrer les images à afficher en fonction de la prop isHomePage
  const imagesToDisplay = isHomePage ? images.slice(0, 5) : images;

  return (
    <div>
      <h2>Galerie photo</h2>
      {isAuthenticated && (
        <form onSubmit={handleUpload}>
          <input type="file" onChange={(e) => setFile(e.target.files[0])} />
          <button type="submit">Upload</button>
        </form>
      )}

      <div className="gallery">
        {imagesToDisplay.map(image => (
          <div key={image._id} className="image-container">
            <img src={`${process.env.REACT_APP_API_URL}/${image.uploadPath}`} alt={image.filename} onClick={() => openModal(image)} />
            {isAuthenticated && (
              <button onClick={() => handleDelete(image._id)}>Delete</button>
            )}
          </div>
        ))}
      </div>

      {isHomePage && images.length > 6 && (
        <Link to="/galerie-photo">
          <button className='bouton-voir-plus'>Voir plus</button>
        </Link>
      )}

      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Image Modal" className="modal-content" overlayClassName="modal-overlay">
        {selectedImage && (
          <div className='modale'>
            <img src={`${process.env.REACT_APP_API_URL}/${selectedImage.uploadPath}`} alt={selectedImage.filename} />
            <button className="close-button" onClick={closeModal}>&times;</button>
          </div>
        )}
        <div className="navigation-buttons">
              <button onClick={() => handleNavigation('prev')}>⬅</button>
              <button onClick={() => handleNavigation('next')}>⮕</button>
            </div>
      </Modal>
    </div>
  );
};

export default Gallery;
