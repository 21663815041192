import React from 'react';
import CardImaged from '../../Components/CardImaged/CardImaged';
import './Pedagogie.css'
const CardsPage = () => {
  return (
    <div className='pedagogie-page'>
      <h1>Pédagogie</h1>
      <CardImaged />
    </div>
  );
};

export default CardsPage;
