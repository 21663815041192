import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CardImaged.css';

const CardImaged = () => {
  const [cards, setCards] = useState([]);
  const [form, setForm] = useState({ _id: '', title: '', description: '', image: null });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
      setIsAuthenticated(true);
    }
    fetchCards();
  }, []);

  const fetchCards = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/cards`);
      setCards(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des cartes:', error);
      setError('Erreur lors de la récupération des cartes.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleImageChange = (e) => {
    setForm({ ...form, image: e.target.files[0] });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!isAuthenticated) {
      alert('Vous devez être connecté pour ajouter ou modifier des cartes.');
      return;
    }

    const formData = new FormData();
    formData.append('title', form.title);
    formData.append('description', form.description);
    if (form.image) {
      formData.append('image', form.image);
    }

    try {
      if (form._id) {
        // Si form._id existe, c'est une mise à jour
        await axios.put(`${process.env.REACT_APP_API_URL}/api/cards/${form._id}`, formData, {
          headers: { Authorization: `Bearer ${token}` }
        });

        const updatedCards = cards.map(card => 
          card._id === form._id ? { ...card, ...form, image: form.image ? URL.createObjectURL(form.image) : card.image } : card
        );
        setCards(updatedCards);
        setForm({ _id: '', title: '', description: '', image: null });
        setSuccess('Carte modifiée avec succès.');
      } else {
        // Sinon, c'est un ajout de nouvelle carte
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/cards`, formData, {
          headers: { Authorization: `Bearer ${token}` }
        });

        const newCard = response.data;
        setCards([...cards, newCard]);
        setForm({ _id: '', title: '', description: '', image: null });
        setSuccess('Nouvelle carte ajoutée avec succès.');
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour ou de l\'ajout de la carte:', error);
      setError('Erreur lors de la mise à jour ou de l\'ajout de la carte.');
    }
  };

  const handleDelete = async (id) => {
    if (!isAuthenticated) {
      alert('Vous devez être connecté pour supprimer des cartes.');
      return;
    }

    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/cards/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setCards(cards.filter(card => card._id !== id));
      setSuccess('Carte supprimée avec succès.');
    } catch (error) {
      console.error('Erreur lors de la suppression de la carte:', error);
      setError('Erreur lors de la suppression de la carte.');
    }
  };

  const handleEdit = (card) => {
    setForm({
      _id: card._id,
      title: card.title,
      description: card.description,
      image: null // Reset the image field
    });
  };

  return (
    <div className="CardImaged-container">
      {isAuthenticated && (
        <form onSubmit={handleFormSubmit}>
          <input name="title" placeholder="Titre" value={form.title} onChange={handleInputChange} required />
          <input name="description" placeholder="Description" value={form.description} onChange={handleInputChange} required />
          <input type="file" name="image" onChange={handleImageChange} />
          <button type="submit">{form._id ? 'Modifier Carte' : 'Ajouter Carte'}</button>
        </form>
      )}
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
      {cards.map(card => (
        <div key={card._id} className="CardImaged">
          <img src={`${process.env.REACT_APP_API_URL}/${card.image}`} alt={card.title} />
          <div className="CardImaged-content">
            <h3 className="CardImaged-title">{card.title} </h3>
            <p className="CardImaged-description">{card.description}</p>
            {isAuthenticated && (
              <div className='zone-bouton'>
                <button onClick={() => handleDelete(card._id)} className="delete-button">Supprimer</button>
                <button onClick={() => handleEdit(card)} className="edit-button">Modifier</button>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardImaged;
