import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SuccessRateComponent.css'; // Importer le fichier CSS

const SuccessRateComponent = () => {
  const [results, setResults] = useState([]);
  const [form, setForm] = useState({
    successRate: '',
    date: '',
    tresBien: '',
    bien: '',
    assezBien: ''
  });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
      setIsAuthenticated(true);
    }

    fetchResults(); // Appel initial pour récupérer les résultats
  }, []);

  const fetchResults = async () => {
    try {
      const config = isAuthenticated ? { headers: { Authorization: `Bearer ${token}` } } : {};
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/results`, config);
      setResults(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des résultats:', error);
      setError('Erreur lors de la récupération des résultats.');
    }
  };

  const handleInputChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!isAuthenticated) {
      alert('Vous devez être connecté pour ajouter des résultats.');
      return;
    }

    const newResult = {
      successRate: form.successRate,
      date: form.date,
      mentions: {
        tresBien: form.tresBien,
        bien: form.bien,
        assezBien: form.assezBien
      }
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/results`, newResult, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setResults([...results, response.data]);
      setForm({ successRate: '', date: '', tresBien: '', bien: '', assezBien: '' });
      setSuccess('Résultat ajouté avec succès.');
    } catch (error) {
      console.error('Erreur lors de l\'ajout du résultat:', error);
      setError('Erreur lors de l\'ajout du résultat.');
    }
  };

  const handleDelete = async (id) => {
    if (!isAuthenticated) {
      alert('Vous devez être connecté pour supprimer des résultats.');
      return;
    }

    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/results/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setResults(results.filter(result => result._id !== id));
      setSuccess('Résultat supprimé avec succès.');
    } catch (error) {
      console.error('Erreur lors de la suppression du résultat:', error);
      setError('Erreur lors de la suppression du résultat.');
    }
  };

  const toggleCollapse = (index) => {
    const updatedResults = results.map((result, i) => {
      if (i === index) {
        return { ...result, isOpen: !result.isOpen };
      }
      return result;
    });
    setResults(updatedResults);
  };

  return (
    <div className="success-rate-container">
      {isAuthenticated && (
        <form onSubmit={handleFormSubmit}>
          <input
            name="successRate"
            placeholder="Taux de réussite"
            value={form.successRate}
            onChange={handleInputChange}
            required
          />
          <input
            name="date"
            placeholder="Année"
            value={form.date}
            onChange={handleInputChange}
            pattern="[0-9]{4}"
            required
            title="Veuillez entrer une année valide (format: YYYY)"
          />
          <input
            name="tresBien"
            placeholder="Mention Très Bien (%)"
            value={form.tresBien}
            onChange={handleInputChange}
            required
          />
          <input
            name="bien"
            placeholder="Mention Bien (%)"
            value={form.bien}
            onChange={handleInputChange}
            required
          />
          <input
            name="assezBien"
            placeholder="Mention Assez Bien (%)"
            value={form.assezBien}
            onChange={handleInputChange}
            required
          />
          <button type="submit">Ajouter Résultat</button>
        </form>
      )}
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
      
      {results.map((result, index) => (
        <div key={result._id} className="success-rate">
          <div className="additional-info">
            <p>Date du brevet : {new Date(result.date).getFullYear()}</p> {/* Afficher seulement l'année */}
          </div>
          <div className="success-rate-info">
            <p className="success-rate-percent">{result.successRate}%</p>
            <div className="progress-bar">
              <div className="progress-fill" style={{ width: `${result.successRate}%` }}></div>
            </div>
          </div>
          {result.isOpen && (
            <div className="collapse-content">
              <p>Détails des mentions obtenues :</p>
              <ul>
                <li>Mention Très Bien: {result.mentions.tresBien}%</li>
                <li>Mention Bien: {result.mentions.bien}%</li>
                <li>Mention Assez Bien: {result.mentions.assezBien}%</li>
              </ul>
              {isAuthenticated && (
                <button onClick={() => handleDelete(result._id)} className="delete-button-results">Supprimer</button>
              )}
            </div>
          )}
          <button onClick={() => toggleCollapse(index)} className="collapse-button">
            {result.isOpen ? 'Cacher les détails' : 'Afficher les détails'}
          </button>
        </div>
      ))}
    </div>
  );
};

export default SuccessRateComponent;
