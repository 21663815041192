import React, { useState, useEffect } from 'react';
import './Fonctionnement.css';
import { FiClock, FiMail, FiUsers } from 'react-icons/fi';
import { FaGraduationCap,FaCog } from 'react-icons/fa';
import SectionCard from '../../Components/sectionCard/SectionCard';
import axios from 'axios';

const Fonctionnement = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState('');
  const [sections, setSections] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/sections`)
      .then(response => {
        console.log('Sections from backend:', response.data);
        const sortedSections = response.data.sort((a, b) => a.title.localeCompare(b.title));
        setSections(sortedSections);
      })
      .catch(error => {
        console.error('Error fetching sections:', error);
      });
  }, []);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
      setIsAuthenticated(true);
    }
  }, []);

  const handleSaveSection = (sectionId, editedText) => {
    axios.put(
      `${process.env.REACT_APP_API_URL}/api/sections/${sectionId}`,
      { content: editedText },
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    )
    .then(response => {
      const updatedSections = sections.map(section => {
        if (section._id === sectionId) {
          return { ...section, content: response.data.content };
        }
        return section;
      });
      setSections(updatedSections);
    })
    .catch(error => {
      console.error('Error updating section:', error);
    });
  };

  const getIcon = (title) => {
    switch (title) {
      case 'Effectifs et Accompagnement':
        return <FiUsers className="icon" />;
      case 'Horaires':
        return <FiClock className="icon" />;
      case 'Communication avec les Familles':
        return <FiMail className="icon" />;
      case 'Étude Dirigée':
        return <FaGraduationCap className="icon" />;
        case 'Organisation':
          return <FaCog className="icon" />
      default:
        return null;
    }
  };

  return (
    <div className="fonctionnement">
      <div className="section">
        <h1>Fonctionnement</h1>
      </div>
      
      {sections.map(section => (
        <SectionCard
          key={section._id}
          id={section._id}
          icon={getIcon(section.title)}
          title={section.title}
          content={section.content}
          isAuthenticated={isAuthenticated}
          onSave={(editedText) => handleSaveSection(section._id, editedText)} // Passer la fonction de sauvegarde
        />
      ))}
    </div>
  );
};

export default Fonctionnement;
